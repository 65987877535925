import axios from "axios";
import { request } from "@/utils/request";
// 查询数据
export function feedbackSelect(data) {
  return request({
    url: `/admin/feedback/select`,
    method: "POST",
    data,
  });
}

// 新增数据
export function feedbackAdd(data) {
  return request({
    url: `/admin/feedback/add`,
    method: "POST",
    data,
  });
}

/* 修改数据 */
export function feedbackUpdate(data) {
  return request({
    url: `/admin/feedback/update`,
    method: "POST",
    data,
  });
}

// 删除数据
export function feedbackDelete(data) {
  return request({
    url: `/admin/feedback/delete`,
    method: "POST",
    data,
  });
}
