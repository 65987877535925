<template>
  <div class="diseaseList">
    <!-- 筛选查询框 @change="selectGoodsByIdBtn"-->
    <!-- <el-form :inline="true">
      <el-button type="primary" size="small" v-waves @click="handleAdd"
        >新增</el-button
      >
    </el-form> -->
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="status" slot-scope="{ row }">
          <el-tag
            type="success"
            effect="dark"
            size="mini"
            v-if="row.status == 1"
          >
            已处理
          </el-tag>
          <el-tag type="danger" effect="dark" size="mini" v-else>
            未处理
          </el-tag>
        </template>
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button
              style="color: #afb86d;"
              v-if="row.status == 0"
              size="mini"
              type="text"
              @click="handleStatus(row)"
              primary
            >
              处理</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>

      <!-- 产品提交表单 -->
      <el-dialog
        :title="inpitTypeName"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogForm" label-width="90px">
          <el-form-item label="通知内容" required>
            <el-input
              v-model="dialogForm.message"
              clearable
              type="textarea"
              :rows="4"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="dialogFormVisible = false">取 消</el-button>
          <el-button class="addBtn" type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  feedbackSelect,
  feedbackDelete,
  feedbackAdd,
  feedbackUpdate,
} from "@/services/webCenter/feedback";
import { IP } from "@/services/config.js";
import ElTable from "@/components/elTable/index.vue";
import StoreToString from "@/components/filters/storeToString.vue";
import listHeader from "./listHeader";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    StoreToString,
  },
  created() {
    this.getListData();
  },

  data() {
    return {
      loading: false,
      listQuery: {
        type: "",
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },

      goodsTitle: "",
      tableData: [],
      // 一级内容 二级内容 内容
      select_two_id: "",
      typeList: [
        { id: 1, name: "作者" },
        { id: 2, name: "艺术家" },
      ],
      categoryArr_one: [], //遍历一级内容
      parentId: "", //增加二级内容时选择的一级内容id
      isfeedbackAddOne: 1, //增加一级内容还是二级内容
      dataList: [],
      goodsArr: [],
      dialogFormVisible: false,
      inpitTypeName: "",
      formLabelWidth: "120px",
      // action: "http://127.0.0.1:6688/uploadFile/add",
      action: IP + "/uploadFile/add",
      fileList: [],
      pageCount: "",
      pageSize: 8,
      dialogForm: { store_id: "" },
      shuf: [],
      cover: [],
      describe: [],
    };
  },
  computed: {
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  watch: {},
  methods: {
    async getListData() {
      console.log("getListData", this.listQuery);
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      this.loading = true;
      const { data, code, count } = await feedbackSelect(listQuery);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },

    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    handleAdd(type) {
      this.dialogFormVisible = true;
      this.inpitTypeName = "新增内容";
      this.fileList = [];
      this.dialogForm = {};
      this.isfeedbackAddOne = 1;
    },

    /* 修改 */
    handleEdit(row) {
      this.fileList = [];
      this.dialogFormVisible = true;
      this.inpitTypeName = "修改内容";
      this.dialogForm = JSON.parse(JSON.stringify(row));
      // if (this.dialogForm.store_id) {
      //   this.dialogForm.store_id = this.dialogForm.store_id?.split(",");
      // }
    },

    handleSuccess_cover(e) {
      this.dialogForm.img = e.data;
    },

    /* 处理 */
    handleStatus(row) {
      this.$confirm(`是否确认处理该意见反馈？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        feedbackUpdate({ id: row.id, status: 1 }).then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "操作成功",
              offset: 100,
            });
            this.getListData();
          } else {
            this.$message({
              type: "error",
              message: "操作失败",
              offset: 100,
            });
          }
        });
      });
    },

    /* 保存 */
    save() {
      //必填字段校验
      if (!this.dialogForm.message) {
        this.$message.error("请填写必填字段");
        return;
      }
      // if (this.dialogForm.store_id.length > 0) {
      //   console.log("this.dialogForm.store_id=====", this.dialogForm.store_id);
      //   this.dialogForm.store_id = this.dialogForm.store_id.join(",");
      // }
      if (this.inpitTypeName == "新增内容") {
        feedbackAdd({ ...this.dialogForm }).then((res) => {
          console.log(res);
          if (res.code) {
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.dialogFormVisible = false;
            this.getListData();
          }
        });
      }
      if (this.inpitTypeName == "修改内容") {
        feedbackUpdate(this.dialogForm).then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.dialogFormVisible = false;
            this.getListData();
          }
        });
      }
    },

    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm(`此操作将永久删除该条数据, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          feedbackDelete({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.diseaseList {
  width: 100%;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  /* max-width: 1405px; */
  margin: 10px auto;
}

.goodsTitle:hover {
  cursor: pointer;
}
.upload-demo {
  margin: 20px;
}
</style>
