export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    // { label: "提交者openid", prop: "openid" },
    { label: "提交者昵称", prop: "name", width: 200 },
    { label: "提交者手机号", prop: "phone", width: 200 },
    { label: "反馈内容", prop: "message", width: 490 },
    {
      label: "提交时间",
      prop: "createTime",
      width: 200,
      // slots: "state",
    },
    { label: "是否处理", prop: "status", slots: "status", width: 200 },
    {
      label: "操作",
      prop: "operation",
      width: 200,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
